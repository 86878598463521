import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import SectionBoxCardText from '../../components/Platfrom/SectionBoxCardText'
import SectionBoxReady from '../../components/outcomes/SectionBoxReady'
import { FaqAccordionBlock } from '../../components/Blocks/AccordionBlock'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'
// import Section from "../components/section"
// import { graphql } from 'gatsby'

const Attribution = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const data = [
          {
               title: 'Step 1:',
               content: 'Implement',
               desc:
                    'Deploy ListenLayer in a matter of minutes and start to collect better marketing data from your website. Enable Reveal to identify real people and companies.',
          },
          {
               title: 'Step 2:',
               content: 'Connect Data',
               desc:
                    'Connect external CRM data or import your revenue and pipeline information manually or on a schedule. Map the data to ListenLayer in 3 easy steps.',
          },
          {
               title: 'Step 3:',
               content: 'Learn & Distribute',
               desc: 'Watch as ListenLayer generates unmatched insights into how your marketing activity generates revenue and sales pipeline. ',
          },
     ]

     const dataCartText = [
          {
               title: 'B2B Revenue Attribution',
               desc:
                    'ListenLayer provides powerful B2B analytics and revenue attribution. Connect Salesforce.com, define your data structure, outline your sales pipeline stages, and begin to gain real insights into what drives revenue. ',
          },
          {
               title: 'eCommerce Attribution',
               desc:
                    "Advertising platforms consistently over or under attribute their contribution to revenue. Develop a single source of truth with eCommerce analytics and attribution data that's accurate and comprehensive. ",
          },
          {
               title: 'Reveal Real People & Companies',
               desc:
                    'Gone are the days of anonymous "conversions" and behavioral analytics. Identify real people that have interacted with your website and reveal the businesses they work for.',
          },
          {
               title: 'Remain Compliant',
               desc:
                    'Centralize your web and marketing compliance into a single tool. ListenLayer is compatible with GDPR, CCPA/CPRA, other privacy laws, and is one of the only HIPAA compliant analytics and tagging tools. You can even replace your current CMP!',
          },
     ]

     const headline = 'Frequently Asked Questions'

     const faqs = [
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         "<p>Yes! Google has taught us for years that \"your data must be anonymous...\" But, that's because it's not your data, it's their data! As long as we respect privacy laws in various regions (which ListenLayer's regional consent rules do), then identifying your own leads and customers alongside your marketing data is perfectly okay!</p>\n",
                    faqQuestion: 'Are we allowed to identify people in our marketing data?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         '<p>At the end of the day, people buy from you.  By identifying your leads and customers in your web analytics data, you can finally tie marketing activity to revenue! ListenLayer allows you to connect outside data sources to your marketing data, giving you a full view of attribution. </p>\n',
                    faqQuestion: 'What is the benefit of identifying people?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         '<p>Yes. You can use ListenLayer and gain all the benefits of the platform while not enabling the Reveal People feature.</p>\n',
                    faqQuestion: 'Is it optional to identify people in my analytics data?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         "<p>ListenLayer's Reveal Companies feature does require an additional subscription. Some of our higher plans include a certain number of monthly matches, and additional matching can be purchased.</p>\n",
                    faqQuestion: 'What is the cost of identifying companies and people?',
               },
               type: 'WpCptFaq',
          },
     ]

     const buttonText = ''

     const pageName = 'Revenue Attribution'
     const breadcrumbItems = [
          {
               name: 'Platform',
               url: `${'platform'}`,
          },
          {
               name: `${pageName}`,
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO classBody="custom-centralized-marketing" canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro custom-platform-intro">
                    <div className="circle-green"></div>
                    <div className="circle-blue"></div>
                    <div className="circle-yellow"></div>
                    <div className="container">
                         <div className="platform-intro-title">
                              <h1 className="mt-3">Revenue Attribution for Marketers</h1>
                              <p>
                                   ListenLayer knows every touchpoint, session, user, person, and company interacting with your marketing - so you can
                                   finally achieve attribution!
                              </p>
                         </div>
                    </div>
               </section>

               <section className="platform-step-card">
                    <div className="container">
                         <div className="box-card-step custom-box-card-step">
                              <div className="row">
                                   {data &&
                                        data.map(item => (
                                             <div className="col-md-12 col-lg-4 mb-4">
                                                  <div className="card-step h-100">
                                                       <div className="card-body mb-0">
                                                            <h3>{item.title}</h3>
                                                            <p className="card-text">{item.content}</p>
                                                            <p className="card-desc">{item.desc}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                              </div>
                         </div>
                    </div>
               </section>

               <SectionBoxCardText data={dataCartText} className={'custom-background'} />

               <SectionBoxReady
                    title={'Ready to Dive In?'}
                    content={'See how easy (and powerful) ListenLayer is by opening an account today or requesting a demo.'}
                    style={'30px'}
               />

               <section className="enhance-faq">
                    <div className="container">
                         <FaqAccordionBlock faqs={faqs} buttonText={buttonText} headline={headline} />
                    </div>
               </section>
          </Layout>
     )
}

export default Attribution
